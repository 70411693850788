<template>
    <v-container :loading="loading" fluid dense>
        <v-btn rounded class="ma-0 pa-0" text :loading="loading" disabled>
            <v-chip v-if="items" :to="`sessao/${parseInt(objetoCurrent.id)}`" class="white--text" :color="items.cor" target="_blank">
                {{ lable_id }} {{ (saldo * 100).toFixed(2) }}% <v-icon>{{ items.icone }} </v-icon> 
            </v-chip>
        </v-btn>
    </v-container>
</template>
<script>
import api from '../../../http';

export default{
    name:'Saldo',
    props: {url:{type:String},urlv:{type:String},objetoCurrent:{type:Object},lista:{type:Array}},
    data:()=>({
        headers:[], 
        items:null, 
        loading:true, 
        saldo:null, 
        lable_id:'',
        qtd_evol:null,
        qtd_assinadas: null,
    }),
    methods:{
        async get_listas(url){
            const res = await api.get(`${url}&referencia=${this.saldo}`)
            try{
                const foundItem = res.data.find(e => eval(`${this.saldo} ${e.operador_logico} ${e.valor}`));
                this.items = foundItem || { cor: 'defaultColor', icone: 'defaultIcon' }; // Defina um valor padrão
            }
            catch (e) {
                console.error(e);
            }
            finally {
                this.loading = false;
            }
        },
        async get_saldo(){

            if(this.urlv!==''){
                const res = await api.get(this.urlv)
                try{
                    res.data.forEach(e=>{
                        this.qtd_evol += parseFloat(e.qtd_evol)
                        this.qtd_assinadas += parseFloat(e.qtd_assinadas)
                    })
                    this.get_listas(this.url)
                    this.saldo = this.qtd_assinadas  / this.qtd_evol
                    this.lable_id = `ID:${this.objetoCurrent.id} - ` ; 
                }
                catch{e=>{console.log(e)}}
            }else{
                this.saldo += parseFloat(this.objetoCurrent.percent_tt)
                
                this.get_listas(this.url)
            }
            this.$emit('emit_saldo', this.saldo)
        },
    },
    mounted(){
        this.get_saldo()    
    }
}
</script>